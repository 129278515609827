/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './menu.module.scss';

import Monaco from '../../shared/monaco';
import { ArcButton } from '@retisio/ui';
import { MENUSERVICES } from './menu.services';

export default function MenuCreation() {
  const [initialValues, setData] = useState();
  const [menuList, setMenuList] = useState();

  useEffect(() => {
    getAppDetails();
  }, []);

  const getAppDetails = () => {
    MENUSERVICES.getMenuList()
      .then((resbal) => {
        const list = ['bxc', 'cxc', 'damui', 'pxc', 'sxm', 'settings', 'csa', 'manageorders', 'dxc'];
        const reorderedApp = list
          .map((id) => resbal['applications'].find((item) => item && item.id === id))
          .filter(Boolean);
        const menu = {
          applications: reorderedApp
        };
        setMenuList(menu);
      }, (err) => {
        console.log(err);
      });
  };

  const updateData = (val) => {
    setData(val);
  };

  const saveChanges = (data) => {
    if (data === 'update') {
      MENUSERVICES.updateMenu(initialValues)
        .then((resbal) => {
          console.log(resbal, menuList);
          setMenuList(resbal);
          getAppDetails();
        }, (err) => {
          console.log(err);
        });
    } else {
      MENUSERVICES.createMenu(initialValues)
        .then((resbal) => {
          console.log(resbal, menuList);
          setMenuList(resbal);
          getAppDetails();
        }, (err) => {
          console.log(err);
        });
    }

  };


  const deleteApps = () => {
    let data = {
      applicationIds: ["sxc", "damui", 'bxc', "settings", "cxc", "pxc", "manageorders", 'sxm', 'csa', 'dxc']
    };
    MENUSERVICES.deleteApps(data)
      .then((resbal) => {
        getAppDetails();
      }, (err) => {
        console.log(err);
      });
  };



  return (
    <div className={styles.wrapper}>
      <div className={styles.btn}>
        <h6>Top Navigation</h6>
        <ArcButton label={'Create'} type="primary" onClick={saveChanges} />
        <ArcButton label={'update'} type="primary" onClick={() => saveChanges('update')} />
        <ArcButton label={'Delete'} type="primary" onClick={deleteApps} />
      </div>
      <Monaco
        className={styles.JSONdata}
        height="calc(100vh - 20rem)"
        width="60rem"
        language="json"
        JSONdata={menuList}
        editorOnChange={(val) => updateData(val)}
      />

    </div>
  );
}


