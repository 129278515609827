import API from '@retisio/api';

export const MENUSERVICES = {
  getMenuList() {
    return API.get(`/api/rbac/api/v1/applications`);
  },
  createMenu(data) {
    return API.post(`/api/rbac/api/v1/applications`, {
      data: data,
      header: {
        'x-rbac-key': 'retisio@123'
      }
    });
  },
  updateMenu(data) {
    return API.update(`/api/rbac/api/v1/applications`,
      {
        data: data,
        header: {
          'x-rbac-key': 'retisio@123'
        }
      });
  },
  deleteApps(data){
    return API.delete(`/api/rbac/api/v1/applications`, {
      data: data,
      header: {
        'x-rbac-key': 'retisio@123'
      }
    });
  }
};