
import React from "react";
import { Auth } from "@retisio/rbac";
import { ArcFooter } from "@retisio/ui";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import TopNavigation from './layout/topnavigation';



function App(props) {
  return (
    <BrowserRouter basename={'/' + process.env.REACT_APP_DOC_ID}>
      <Auth>
        <TopNavigation />
        <div className="appContainer">
          <Router />
        </div>
        <ArcFooter copyRightText = "© Retisio Inc. Copyright 2024 | RETISIO V4.2.0"/>
      </Auth>
    </BrowserRouter>
  );
}

export default App;
